import { useSession } from '@hooks/index';
import { StatsigProvider, useClientAsyncInit } from '@statsig/react-bindings';
import { useEffect } from 'react';
import { runStatsigSessionReplay } from '@statsig/session-replay';
import { runStatsigAutoCapture } from '@statsig/web-analytics';
import { useTrackEvent } from './trackEvent';
import { useRouter } from 'next/router';

function AuthSuccessListener({ children }: { children: React.ReactNode }) {
  const { user } = useSession();
  const { query } = useRouter();
  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    if (query.authSuccess) {
      trackEvent('login', {
        value: user?.id
      });
    }
  }, [query.authSuccess]);

  return children;
}

export const AnalyticsStatsigProvider = ({ ...props }) => {
  const { user, isLoggedIn } = useSession();
  const { client } = useClientAsyncInit(process.env.NEXT_PUBLIC_STATSIG_CLIENT_KEY!, {
    userID: user?.id,
    customIDs: {
      stableID: user?.email
    },
    email: user?.email,
    custom: {
      username: user?.username
    }
  });

  useEffect(() => {
    if (isLoggedIn && user) {
      const context = client.getContext();
      if (!context.user?.customIDs?.stableID) {
        client.updateUserSync({
          userID: user?.id,
          email: user?.email
        });
      }
    }
  }, [isLoggedIn, user]);

  useEffect(() => {
    runStatsigAutoCapture(client);
    runStatsigSessionReplay(client);
  }, [client]);

  return (
    <StatsigProvider client={client}>
      <AuthSuccessListener>{props.children}</AuthSuccessListener>
    </StatsigProvider>
  );
};
