import { AnalyticsEvents } from '@analytics/AnalyticsEvents';
import { convertObjectKeys } from '@lib/convertObjectKeys';
import { snakeCase } from 'lodash';
import { useStatsigClient } from '@statsig/react-bindings';
import { EventMap } from './EventMap';

const environment = process.env.NEXT_PUBLIC_VERCEL_ENV || 'development';

// statsig metadata is limited to strings, so we need to convert the values to strings
function parseMetadata(metadata: Record<string, number | string | undefined> | undefined) {
  return Object.entries(metadata || {}).reduce<Record<string, string>>((acc, [key, value]) => {
    acc[key] = typeof value === 'number' ? value.toString() : value || '';
    return acc;
  }, {});
}

export function trackEvent(event: AnalyticsEvents, properties?: Record<string, any>) {
  const hasWindow = typeof window !== 'undefined';
  const defaultProperties = {
    location: {
      pathname: hasWindow ? window.location?.pathname : '',
      hostname: hasWindow ? window.location?.hostname : ''
    }
  };
  const formattedProperties = convertObjectKeys({ ...defaultProperties, ...properties }, snakeCase);
  const formattedEvent = snakeCase(event);
  if (environment !== 'production') {
    console.log('Amplitude trackEvent skipped - not production environment', {
      event: formattedEvent,
      properties: formattedProperties
    });
  } else {
    console.warn('Not implemented. Please use useTrackEvent() hook instead.');
  }
}

export function useTrackEvent() {
  const { client } = useStatsigClient();

  function trackEvent<K extends keyof EventMap>(event: K, properties: EventMap[K]) {
    client.logEvent(
      event,
      properties.value ? properties.value : undefined,
      properties.metadata ? parseMetadata(properties.metadata) : undefined
    );
  }

  return { trackEvent };
}
