import { ThemeResult } from '@components/Create/themes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Modifier } from '@lib/getModifiers';
import { ActiveStylesItem, CreateState, NegativeStylesItem } from './types';

export const initialState: CreateState = {
  isCreating: false,
  isArchived: false,
  isPrivate: false,
  prompt: '',
  canvas: 'square',
  imageCount: 1,
  output: 'standard',
  referenceImage: undefined,
  maskImage: undefined,
  referencePercentage: 15,
  service: 'Stable Diffusion',
  cfgScale: 7,
  steps: 25,
  seed: '',
  sampler: 'Choose automatically',
  // sampler: '',
  activeStyles: [
    {
      type: 'subject',
      data: 'Subjcet',
      id: 'subject-0',
      enabled: true,
      weight: '100%'
    }
  ],
  negativeStyles: [],
  editingTheme: {
    id: '',
    name: '',
    modifiers: [
      {
        id: 'subject',
        type: 'subject',
        data: 'subject',
        enabled: true
      }
    ],
    thumbnails: [],
    meta: {},
    userId: null,
    image: null,
    accentColor: null,
    negativeModifiers: [],
    category: null,
    isPro: false
  },
  stableDiffusionVersion: 'stable-diffusion-v1-5',
  prompts: [],
  midjourneyCreationStatus: {
    isInProgress: false,
    progress: 0,
    asset: null
  },
  clipGuidance: 'NONE',
  customModel: 'PhChroma3',
  visibleItems: 0,
  isLoadingSubject: false,
  controlNetItems: [],
  queuePosition: {
    position: 0,
    totalTimeEstimate: 0
  }
};

export const _createSlice = createSlice({
  name: 'create',
  initialState,
  reducers: {
    setQueuePosition: (state, action: PayloadAction<CreateState['queuePosition']>) => {
      state.queuePosition = action.payload;
    },
    setControlNetItems: (state, action: PayloadAction<CreateState['controlNetItems']>) => {
      state.controlNetItems = action.payload;
    },
    setIsLoadingSubject: (state, action: PayloadAction<CreateState['isLoadingSubject']>) => {
      state.isLoadingSubject = action.payload;
    },
    setIsArchived: (state, action: PayloadAction<CreateState['isArchived']>) => {
      state.isArchived = action.payload;
    },
    setIsPrivate: (state, action: PayloadAction<CreateState['isPrivate']>) => {
      state.isPrivate = action.payload;
    },
    setPrompt: (state, action: PayloadAction<CreateState['prompt']>) => {
      state.prompt = action.payload;
    },
    setCanvas: (state, action: PayloadAction<CreateState['canvas']>) => {
      state.canvas = action.payload;
    },
    setImageCount: (state, action: PayloadAction<CreateState['imageCount']>) => {
      state.imageCount = action.payload;
    },
    setOutput: (state, action: PayloadAction<CreateState['output']>) => {
      state.output = action.payload;
    },
    setReferenceImage: (state, action: PayloadAction<CreateState['referenceImage']>) => {
      state.referenceImage = action.payload;
    },
    setMaskImage: (state, action: PayloadAction<CreateState['maskImage']>) => {
      state.maskImage = action.payload;
    },
    setReferencePercentage: (state, action: PayloadAction<CreateState['referencePercentage']>) => {
      state.referencePercentage = action.payload;
    },
    setService: (state, action: PayloadAction<CreateState['service']>) => {
      state.service = action.payload;
    },
    setCfgScale: (state, action: PayloadAction<CreateState['cfgScale']>) => {
      state.cfgScale = action.payload;
    },
    setSteps: (state, action: PayloadAction<CreateState['steps']>) => {
      state.steps = action.payload;
    },
    setSeed: (state, action: PayloadAction<CreateState['seed']>) => {
      state.seed = action.payload;
    },
    setSampler: (state, action: PayloadAction<CreateState['sampler']>) => {
      state.sampler = action.payload;
    },
    setClipGuidance: (state, action: PayloadAction<CreateState['clipGuidance']>) => {
      state.clipGuidance = action.payload;
    },
    setActiveStyles: (state, action: PayloadAction<CreateState['activeStyles']>) => {
      state.activeStyles = action.payload;
    },
    toggleStyle: (state, action: PayloadAction<Modifier>) => {
      const style = action.payload;
      const index = state.activeStyles.findIndex(item => item.type !== 'subject' && item.data.id === style.id);
      if (index === -1) {
        state.activeStyles.push({
          type: 'modifier',
          data: style,
          id: style.id,
          enabled: true
        });
      } else {
        // @ts-ignore TODO: Fix typing
        state.activeStyles[index].enabled = !state.activeStyles[index].enabled;
      }
    },
    setEditingThemeStyles: (state, action: PayloadAction<ActiveStylesItem[]>) => {
      const modifiers = action.payload
        .map(item => {
          switch (item.type) {
            case 'modifier':
              return {
                ...item.data,
                enabled: item.enabled,
                type: 'modifier'
              };

            default:
              return item;
          }
        })
        .flat();
      // @ts-ignore TODO: Fix typing
      state.editingTheme.modifiers = modifiers;
    },
    setEditingThemeNegativeStyles: (state, action: PayloadAction<NegativeStylesItem[]>) => {
      const negativeModifiers = action.payload
        .map(item => {
          switch (item.type) {
            case 'modifier':
              return {
                ...item.data,
                enabled: item.enabled,
                type: 'modifier'
              };

            default:
              return item;
          }
        })
        .flat();
      // @ts-ignore TODO: Fix typing
      state.editingTheme.negativeModifiers = negativeModifiers;
    },
    toggleEditingThemeStyle: (state, action: PayloadAction<Modifier>) => {
      const style = action.payload;
      const editingThemeStyles = (state.editingTheme.modifiers as Modifier[]) ?? [];
      const index = editingThemeStyles.findIndex(item => item.type === 'modifier' && item.id === style.id);
      if (index === -1) {
        editingThemeStyles.push({ ...style, type: 'modifier', enabled: true });
      } else {
        // @ts-ignore TODO: Fix typing
        editingThemeStyles[index].enabled = !editingThemeStyles[index].enabled;
      }
    },
    toggleEditingThemeNegativeStyle: (state, action: PayloadAction<Modifier>) => {
      const style = action.payload;
      const editingThemeNegativeStyles = (state.editingTheme.negativeModifiers as Modifier[]) ?? [];
      const index = editingThemeNegativeStyles.findIndex(item => item.type === 'modifier' && item.id === style.id);
      if (index === -1) {
        editingThemeNegativeStyles.push({ ...style, type: 'modifier', enabled: true });
      } else {
        // @ts-ignore TODO: Fix typing
        editingThemeNegativeStyles[index].enabled = !editingThemeNegativeStyles[index].enabled;
      }
    },
    setTheme: (state, action: PayloadAction<ThemeResult | undefined>) => {
      const theme = action.payload;

      const index = state.activeStyles.findIndex(item => item.type === 'theme');

      if (!theme) {
        if (index !== -1) {
          // @ts-ignore TODO: Fix typing
          state.activeStyles[index].enabled = false;
        }
        return;
      }

      if (index === -1) {
        state.activeStyles.push({
          type: 'theme',
          data: theme,
          id: theme.id,
          enabled: true
        });
      } else {
        state.activeStyles[index] = {
          type: 'theme',
          data: theme,
          id: theme.id,
          enabled: true
        };
      }
    },
    setEditingTheme: (state, action: PayloadAction<ThemeResult | undefined>) => {
      const theme = action.payload;
      if (!theme) {
        state.editingTheme = initialState.editingTheme;
      } else {
        state.editingTheme = theme;
      }
    },
    setEditingThemeParams: (state, action: PayloadAction<Partial<ThemeResult>>) => {
      const params = action.payload;
      state.editingTheme = {
        ...state.editingTheme,
        ...params
      };
    },

    resetState: () => initialState,
    setState: (state, action: PayloadAction<Partial<CreateState>>) => {
      return { ...state, ...action.payload };
    },
    setIsCreating: (state, action: PayloadAction<boolean>) => {
      state.isCreating = action.payload;
    },
    setStableDiffusionVersion: (state, action: PayloadAction<CreateState['stableDiffusionVersion']>) => {
      state.stableDiffusionVersion = action.payload;
      state.customModel = 'None';
    },
    setPrompts: (state, action: PayloadAction<CreateState['prompts']>) => {
      state.prompts = action.payload;
    },
    unShiftPrompt: (state, action: PayloadAction<CreateState['prompts'][0]>) => {
      state.prompts.unshift(action.payload);
    },
    setMidjourneyCreationStatus: (state, action: PayloadAction<CreateState['midjourneyCreationStatus']>) => {
      state.midjourneyCreationStatus = action.payload;
    },
    setNegativeStyles: (state, action: PayloadAction<CreateState['negativeStyles']>) => {
      state.negativeStyles = action.payload;
    },
    setCustomModel: (state, action: PayloadAction<CreateState['customModel']>) => {
      state.customModel = action.payload;
    },
    setEffects: (state, action: PayloadAction<CreateState['effects']>) => {
      state.effects = action.payload;
    },
    setVisibleItems: (state, action: PayloadAction<CreateState['visibleItems']>) => {
      state.visibleItems = action.payload;
    },
    incrementVisibleItems: state => {
      state.visibleItems = state.visibleItems + 1;
    }
  }
});

export const {
  incrementVisibleItems,
  setVisibleItems,
  setEffects,
  setIsArchived,
  setIsPrivate,
  setPrompt,
  setCanvas,
  setImageCount,
  setOutput,
  setReferenceImage,
  setMaskImage,
  setReferencePercentage,
  setService,
  setCfgScale,
  setSteps,
  setSeed,
  setSampler,
  resetState,
  setState,
  setActiveStyles,
  toggleStyle,
  setTheme,
  setEditingTheme,
  setEditingThemeParams,
  setEditingThemeStyles,
  setEditingThemeNegativeStyles,
  toggleEditingThemeStyle,
  toggleEditingThemeNegativeStyle,
  setIsCreating,
  setStableDiffusionVersion,
  setPrompts,
  unShiftPrompt,
  setMidjourneyCreationStatus,
  setClipGuidance,
  setNegativeStyles,
  setCustomModel,
  setIsLoadingSubject,
  setControlNetItems,
  setQueuePosition
} = _createSlice.actions;

export default _createSlice.reducer;
