import { prisma } from '@acme/db/client'
import { Gender_Association } from '@acme/db';
import { TrainingMeta } from './types/trainingMeta';
import { removeExtraSpaces } from './removeExtraSpaces';
import { getGenderAssociation } from './getGenderAssociation';
import { getTrigger } from './trigger/getTrigger';

interface ProcessedPrompts {
  prompts: string[];
  negativePrompts: string[];
}

const processPromptTriggers = async (
  prompt: string,
  negativePrompt: string,
  trainingMeta: TrainingMeta,
  count: number,
  modifiers?: { [key: string]: string } | undefined
): Promise<ProcessedPrompts> => {
  const trigger = removeExtraSpaces(getTrigger(trainingMeta));

  prompt = replacePromptPlaceholders(prompt, trigger, trainingMeta);
  negativePrompt = replacePromptPlaceholders(negativePrompt, trigger, trainingMeta);

  const tags = prompt.match(/{.*?}/g) ?? [];
  const tagNames = tags.map(tag => tag.slice(1, -1));

  console.log({ tagNames });

  const categories = await fetchModifiers(tagNames, trainingMeta.gender === 'Male' ? 'man' : 'woman');

  return generatePrompts(prompt, negativePrompt, count, categories, modifiers);
};



const replacePromptPlaceholders = (text: string, trigger: string, trainingMeta: TrainingMeta): string => {
  // Replace {trigger} with the actual trigger
  text = text.replaceAll('{trigger}', trigger);


  // Replace {eyeColor} with the eye color if available
  if (trainingMeta?.eyeColor) {
    text = text.replaceAll('{eyeColor}', trainingMeta.eyeColor);
  }


  // Remove any remaining placeholders
  text = text
    .replaceAll('{eyeColor}', '')

  return text;
};

const fetchModifiers = async (tagNames: string[], gender: string) => {
  const genderAssociation = getGenderAssociation(gender);
  return prisma.modifier.findMany({
    where: {
      tag_name: { in: tagNames },
      gender_association: {
        in: genderAssociation
          ? [genderAssociation, Gender_Association.gender_neutral]
          : [Gender_Association.gender_neutral]
      }
    }
  });
};

const generatePrompts = (
  prompt: string,
  negativePrompt: string,
  count: number,
  categories: Awaited<ReturnType<typeof fetchModifiers>>,
  modifiers?: { [key: string]: string } | undefined
): ProcessedPrompts => {
  const prompts: string[] = [];
  const negativePrompts: string[] = [];

  for (let i = 0; i < count; i++) {
    let promptCopy = prompt;

    // Process all modifiers
    if (modifiers) {
      for (const [key, value] of Object.entries(modifiers)) {
        if (value !== 'Random') {
          console.log(`Replacing {${key}} with`, value);
          promptCopy = promptCopy.replaceAll(`{${key}}`, value);
        }
      }
    }

    const modifiersByTagName = categories.reduce((acc, modifier) => {
      if (!acc[modifier.tag_name!]) {
        acc[modifier.tag_name!] = [];
      }
      acc[modifier.tag_name!]!.push(modifier);
      return acc;
    }, {} as Record<string, typeof categories>);

    // For each group, select a random modifier and then update the promptCopy
    for (const [tagName, modifiers] of Object.entries(modifiersByTagName)) {
      const validModifiers = modifiers;
      if (validModifiers.length > 0) {
        const randomModifier = validModifiers[Math.floor(Math.random() * validModifiers.length)];
        promptCopy = promptCopy.replaceAll(`{${tagName}}`, randomModifier?.value ?? '');
      }
    }

    prompts.push(promptCopy);
    negativePrompts.push(negativePrompt);
  }

  return { prompts, negativePrompts };
};

export { processPromptTriggers };